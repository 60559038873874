exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buyer-agent-australia-properties-index-js": () => import("./../../../src/pages/buyer-agent-australia-properties/index.js" /* webpackChunkName: "component---src-pages-buyer-agent-australia-properties-index-js" */),
  "component---src-pages-cn-buyer-agent-australia-properties-index-js": () => import("./../../../src/pages/cn/buyer-agent-australia-properties/index.js" /* webpackChunkName: "component---src-pages-cn-buyer-agent-australia-properties-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-general-page-js": () => import("./../../../src/templates/general-page.js" /* webpackChunkName: "component---src-templates-general-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/team-page.js" /* webpackChunkName: "component---src-templates-team-page-js" */),
  "component---src-templates-testimonials-js": () => import("./../../../src/templates/testimonials.js" /* webpackChunkName: "component---src-templates-testimonials-js" */)
}

